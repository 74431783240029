<template>
  <div 
  class="portrait"
  @click="$event.currentTarget.classList.toggle('open')"
  >
    <div class="staffmember-inner">
      <div 
      class="image"
      v-bind:style="style" 
      ></div>
      <div class="staffmember-info">
        <div class="primary">
          <strong class="name">{{ portrait.name }}</strong><br>
        </div>
        <div class="secondary">
          <em>{{ portrait.title }}</em><br>
          {{ portrait.email }}<br>
          {{ portrait.phone }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Portrait',
  props: {
    portrait: Object
  },
  data: function() {
    return {
    }
  },
  mounted: function(){
    this.portrait.open = false;
  },
  methods: {
  },
  computed: {
    style: function(){
      return {
        backgroundImage: 'url(\'' + require('../assets/staffmembers/'+ this.portrait.image) + '\')'
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.portrait {
  width: 320px;
  height: 480px;
  max-width: 12vw;
  max-height: 17vw;
  margin: 5px;
  background: #fff;
  position: relative;
  overflow: hidden;
  z-index: 802;
  width: 160px;
  height: 240px;

  @media (min-width: 1140px){
    min-width: 170px;
  }

  &.open {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    z-index: 1199;
    margin: 0;

    .staffmember-inner {
      position: absolute;
      top: 50vh;
      left: 50vw;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1200;
      width: 320px;
      height: 480px;
      max-width: 320px;
      max-height: 480px;

      @media (max-height: 480px){
        height: 100vh;
        max-height: 100vh;
      }

      .staffmember-info {
        top: auto;

        .primary, .secondary {
          display: block;
        }
      }
    }
  }

  .staffmember-info {
    padding: 10px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    // top: 100%;
    background: rgba(255,255,255,0.9);
    transition: transform 0.25s;
    font-size: 15px;

    @media (max-width: 1279px) {
      font-size: 12px;
      padding: 4px 6px;
    }

    .primary {
      display: block;
    }
    .secondary {
      display: none;
    }
  }
}

.image {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
