<template>
  <div class="hotspot" v-bind:name="slug" v-bind:style="style" v-on:click="openHotspot">
    <ul v-bind:class="{ 'open' : hotspotOpen }">
      <li 
      class="closes"
      v-bind:class="{'open' : hotspotOpen}"
      v-on:click="closeHotspot()"
      ><span>+</span></li>
      <li 
      v-for="(src, srci) in images" 
      v-bind:key="srci"
      v-on:click="openImage(src)"
      v-bind:class="{'open' : hotspotOpen}"
      v-bind:style="{ backgroundImage: 'url(\'' + require('../assets/'+ src ) + '\')' }"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Hotspot',
  props: {
    slug: String,
    width: Number,
    height: Number,
    directOpen: Boolean,
    top: Number,
    left: Number,
    images: Array,
    videos: Array,
    portraits: Array,
    portraitstitle: String
  },
  data: function() {
    return {
      hotspotOpen: false,
    }
  },
  mounted: function(){
  },
  methods: {
    toggleHotspot(){
      this.hotspotOpen = !this.hotspotOpen;
    },
    openHotspot(){
      if( this.directOpen === true ){
        this.$parent.setPortraits(this.portraits);
        console.log('Setting portraitstitle: ' + this.portraitstitle);
        this.$parent.setPortraitsTitle(this.portraitstitle);
      } else {
        this.hotspotOpen = true;
      }
    },
    closeHotspot(){
      let self = this;
      setTimeout(function(){
        self.hotspotOpen = false;
      }, 10);
    },
    openImage(src){
      this.$parent.openImage(src);
    },
    playVideo(src){
      this.$parent.playVideo(src);
    }
  },
  computed: {
    style: function(){
      return {
        width: this.width + 'vw',
        height: this.height + 'vw',
        top: this.top + 'vw',
        left: this.left + 'vw',
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .hotspot {
    z-index: 200;
    position: absolute;
    transform: translateY(-50%) translateX(-50%);
    border-radius: 50%;
    // border: 1px solid #ebebeb;
    
    ul {
      max-height: 0;
      overflow: hidden;
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      width: 200px;
      justify-content: space-between;
      align-content: space-between;
      
      &.open {
        margin-left: 50%;
        margin-top: 70%;
        transform: translateX(-50%) translateY(-50%);
        overflow: visible;
        max-height: 100vh;
        height: 140px;
      }
    }

    li {
      transition: all 0.25s;
      opacity: 0;
      width: 40px;
      height: 40px;
      margin: -10px 15px 0;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      border: 2px solid #282A2B; 
      border-radius: 50%;
      padding: 0.5em;
      color: #fff;
      cursor: pointer;

      &.closes {
        background: #cf0a2c;
        display: flex;
        justify-content: center;
        align-items: center;
        
        span {
          font-size: 25px;
          font-weight: 700;
          transform: rotateZ(45deg);
        }
      }

      &.open {
        margin: -20px 20px 0;
        opacity: 1;
        box-shadow: 0px 0px 32px rgba(0,0,0,0.5);

        &:hover {
          opacity: 0.85;
        }
      }
    }
  }
</style>
