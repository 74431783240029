<template>
  <div 
  class="portraits"
  >
    <button
    class="close"
    v-on:click="closePortraits()"
    >
    +
    </button>
    <div class="portraits-inner">
      <h2>{{ $parent.portraitstitle }}</h2>
      <Portrait
      v-for="(portrait,pi) in portraits"
      v-bind:key="pi"
      :portrait="portrait"
      v-on:click="selectPortrait()"
      >
      </Portrait>
    </div>
  </div>
</template>

<script>
import Portrait from './Portrait.vue'

export default {
  name: 'Portraits',
  components: {
    Portrait
  },
  props: {
    portraits: Array,
    portraitstitle: String,
  },
  data: function() {
    return {
      selectedPortrait: {},
      closePortraits: function(){
        this.$parent.portraits = [];
      }
    }
  },
  mounted: function(){
  },
  methods: {
  },
  computed: {
    style: function(){
      return {
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.portraits {
  position: absolute;
  z-index: 700;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;

  .close {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 20px;
    right: 20px;
    background: #cf0a2c;
    border-radius: 50%;
    color: #fff;
    transform: rotateZ(45deg);
    border: none;
    font-size: 40px;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0;
    z-index: 1001;
    transition: transform 0.25s;

    &:focus {
      outline: none;
      transform: rotateZ(45deg) scale(1.5);
    }
  }

  &:after {
    background: rgba(0,0,0,0.75);
    position: absolute;
    left: -1000px;
    top: -1000px;
    right: -1000px;
    bottom: -1000px;
    content: '';
    z-index: 699;
  }

  .portraits-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    margin-left: 5%;
    margin-right: 5%;
    overflow: scroll;
    padding-top: 5em;

    h2 {
      color: #fff;
      position: absolute;
      top: 1.5em;
      left: 10px;
      right: 10px;
      margin: 0;
      z-index: 800;
      width: 100%;
      z-index: 900;
    }
  }
}
</style>
