<template>
  <div 
  class="videopopper" 
  v-bind:class="{ 'open' : videoPopperOpen }"
  v-bind:style="style" 
  v-on:click="openVideoPopper"
  >
    <ul v-bind:class="{ 'open' : videoPopperOpen }">
      <li 
      class="closes"
      v-bind:class="{'open' : videoPopperOpen}"
      v-on:click="closeVideoPopper"
      ><span>+</span></li>
      <li 
      v-for="(src, srci) in srcs" 
      v-bind:key="srci"
      v-on:click="playVideo(src)"
      v-bind:class="{'open' : videoPopperOpen}"
      v-bind:style="{ backgroundImage: getBackground(src) }"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Videopopper',
  props: {
    width: Number,
    height: Number,
    top: Number,
    left: Number,
    srcs: Array
  },
  data: function() {
    return {
      videoPopperOpen: false,
    }
  },
  mounted: function(){
  },
  methods: {
    openVideoPopper(){
      this.videoPopperOpen = true;
    },
    closeVideoPopper(){
      let self = this;
      setTimeout(function(){
        self.videoPopperOpen = false;
      }, 1);
    },
    playVideo(src){
      if( src.indexOf('.jpg') > -1 ){
        this.$parent.openImage('videos/' + src);
      } else {
        this.$parent.playVideo(src);

      }
    },
    getBackground(src){
      if( src.indexOf('.jpg') > -1 ){
        return 'url(\'' + require('../assets/videos/'+ src ) + '\')'
      } else {
        return 'url(\'' + require('../assets/videos/'+ src +'.jpg') + '\')'
      }
    }
  },
  computed: {
    style: function(){
      return {
        width: this.width + 'vw',
        height: this.height + 'vw',
        top: this.top + 'vw',
        left: this.left + 'vw',
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .videopopper {
    z-index: 400;
    position: absolute;
    // border: 1px solid #ebebeb;

    &.open {
      z-index: 500;
    }

    ul {
      max-height: 0;
      overflow: hidden;
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      width: 200px;
      justify-content: space-between;
      align-content: space-between;
      
      &.open {
        margin-left: 50%;
        margin-top: 0%;
        transform: translateX(-50%) translateY(0%);
        overflow: visible;
        max-height: 100vh;
        height: 140px;
      }
    }

    li {
      transition: all 0.25s;
      opacity: 0;
      width: 40px;
      height: 40px;
      margin: -10px 15px 0;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border: 2px solid #282A2B; 
      border-radius: 50%;
      padding: 0.5em;
      color: #fff;
      cursor: pointer;

      &.closes {
        background: #cf0a2c;
        display: flex;
        justify-content: center;
        align-items: center;
        
        span {
          font-size: 25px;
          font-weight: 700;
          transform: rotateZ(45deg);
        }
      }

      &.open {
        margin: -20px 20px 0;
        opacity: 1;
        box-shadow: 0px 0px 32px rgba(0,0,0,0.5);

        &:hover {
          opacity: 0.85;
        }
      }
    }
  }
</style>
