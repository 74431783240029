<template>
  <div class="presentation">
    <!-- <div class="highlight"></div> -->
    <transition name="fade">
      <div 
      class="lightbox" v-if="lightbox.image"
      v-on:click="lightbox.image = false"
      >
        <div 
        class="lightbox-inner"
        v-bind:style="{ backgroundImage: 'url(\'' + lightbox.image + '\')' }"
        ></div>
      </div>
    </transition>
    <div 
    class="viewport"
    v-bind:class="{ hidden: hideModel }"
    v-on:mousemove="updateMousePosition($event)"
    >
    
      <div 
      class="base-component roof"
      v-on:click="roofOn = false"
      v-bind:class="{ off: !roofOn }"
      v-bind:style="{ backgroundImage: 'url(\'' + sprites.roof + '\')' }"
      >
        <span class="dev-only">ROOF</span>
      </div>
      <div 
      class="base-component floor"
      v-bind:style="{ backgroundImage: 'url(\'' + sprites.floor + '\')' }"
      >
        <Videopopper 
        v-for="(video, vi) in videos" 
        v-bind:key="vi" 
        v-bind:slug="video.slug"
        :srcs="video.srcs" 
        :width="video[viewport].width" 
        :height="video[viewport].height" 
        :top="video[viewport].top" 
        :left="video[viewport].left"
        ></Videopopper>

        <Hotspot 
        v-for="(hotspot) in hotspots"
        v-bind:key="hotspot.slug"
        :slug="hotspot.slug"
        :directOpen="hotspot.directOpen"
        :images="hotspot.images"
        :videos="hotspot.videos"
        :portraits="hotspot.portraits"
        :portraitstitle="hotspot.portraitstitle"
        :width="hotspot.width"
        :height="hotspot.height"
        :top="hotspot[viewport].top"
        :left="hotspot[viewport].left"
        >
        </Hotspot>

        <Transition name="zoomfade">
          <Portraits 
          :portraits="portraits"
          v-if="portraits.length > 0"
          ></Portraits>
        </Transition>

        <span class="dev-only">FLOOR</span>
      </div>
    </div>
    <div class="video-player" 
    v-bind:style="videoStyle"
    >
      <div class="video-container">
        <button 
        class="close"
        v-on:click="closeVideo"
        ><span>+</span></button>
        <video 
        v-bind:src="videoSrc" 
        autoplay 
        v-on:click="closeVideo"
        muted
        controls
        ></video>
      </div>
    </div>
    <div 
    class="controls"
    v-if="portraits.length === 0"
    >
      <div class="group">
        <button v-on:click="roofOn = !roofOn">Tak 
          <span v-if="roofOn">på</span>
          <span v-if="!roofOn">av</span>
        </button>
      </div>
      <div class="group">
        <button 
        v-on:click="setViewport('south')"
        v-bind:class="{'current' : viewport === 'south' }"
        >Söder</button>
        <button 
        v-on:click="setViewport('west')"
        v-bind:class="{'current' : viewport === 'west' }"
        >Väst</button>
        <button 
        v-on:click="setViewport('north')"
        v-bind:class="{'current' : viewport === 'north' }"
        >Norr</button>
        <button 
        v-on:click="setViewport('east')"
        v-bind:class="{'current' : viewport === 'east' }"
        >Öst</button>
      </div>
    </div>
    <!-- <div id="cursor" v-bind:style="{left:mouseleft, top:mousetop}"></div> -->
  </div>
</template>

<script>
import Videopopper from './Videopopper.vue'
import Hotspot from './Hotspot.vue'
import Portraits from './Portraits.vue'
// import Staffmember from './Staffmember.vue'

import hotspotPortraits from '../hotspotPortraits.json'

export default {
  name: 'Presentation',
  components: {
    Videopopper,
    Hotspot,
    Portraits
    // Staffmember
  },
  props: {
  },
  mounted: function(){
    let self = this;
    self.loadHotspotPortraits();

    document.addEventListener("click", this.printMousePos);

    this.hotspots.forEach(function(el){
      if(el.color){
        el.portraits = self.hotspotPortraits[el.color];
        el.portraitstitle = self.hotspotPortraitTitles[el.color];
        el.directOpen = true;
      }
      
    });
    
  },
  data: function() {
    return {
      roofOn: true,
      wallsOn: true,
      viewport: 'east',
      hideModel: false,
      lightbox: {
        image: false,
      },
      staffmemberInfo: {},
      mousetop: 0,
      mouseleft: 0,
      portraits: [],
      videoDisplaying: false,
      videoId: 1,
      videoSrc: false,
      videoHeight: '0px',
      videoOpacity: 0,
      videos: [
        {
          slug: 'idroline-s',
          east: {
            width: 5,
            height: 4,
            top: 28,
            left: 52.5,
          },
          south: {
            width: 4,
            height: 3,
            top: 24,
            left: 51,
          },
          west: {
            width: 7,
            height: 4,
            top: 25,
            left: 41,
          },
          north: {
            width: 5,
            height: 4,
            top: 30,
            left: 43.5,
          },
          srcs: ['optimized/IDROLINE-S.mp4', 'waterjet/IDROLINE-S-JD5AX-CHIUSE2.jpg'],
        },
        {
          slug: 'aida-dsf-m2-4000',
          east: {
            width: 5,
            height: 6,
            top: 45,
            left: 54.5
          },
          south: {
            width: 6,
            height: 6,
            top: 19,
            left: 76
          },
          west: {
            width: 6,
            height: 6,
            top: 8,
            left: 38
          },
          north: {
            width: 8,
            height: 6,
            top: 28.5,
            left: 14.5
          },
          srcs: ['optimized/aida-dsf-m2-4000.mp4', 'aida-dsf/press-feed-uncoiler.jpg', 'aida-dsf/aida-dsf-m2-630.jpg'],
        },
        {
          slug: 'm-shear',
          east: {
            width: 5,
            height: 4,
            top: 22,
            left: 56.5
          },
          south: {
            width: 3,
            height: 4,
            top: 22.5,
            left: 40
          },
          west: {
            width: 4,
            height: 4,
            top: 32,
            left: 39,
          },
          north: {
            width: 4,
            height: 3,
            top: 32.5,
            left: 55.5,
          },
          srcs: ['optimized/M-Shear.mp4', 'm-shear/SafanDarley-M-Shear-with-stacking-system.mp4', 'm-shear/Gradsax.jpg'],
        },
        {
          slug: 'starmatik',
          east: {
            width: 5,
            height: 6,
            top: 38,
            left: 56.5
          },
          south: {
            width: 6,
            height: 6,
            top: 19,
            left: 67
          },
          west: {
            width: 6,
            height: 6,
            top: 14,
            left: 37
          },
          north: {
            width: 8,
            height: 6,
            top: 30.5,
            left: 25.5
          },
          srcs: ['optimized/Starmatik_HBS-with-SAFAN-pressbrake.mp4', 'optimized/Starmatik-Hyper_Bending_System_rev01.mp4', 'starmatik-hyper-bending-system/wica-cold.mp4'],
        },
        {
          slug: 'shear-genius',
          east: {
            width: 12,
            height: 10,
            top: 29,
            left: 69.5,
          },
          south: {
            width: 18,
            height: 10,
            top: 7,
            left: 51,
          },
          west: {
            width: 18,
            height: 10,
            top: 17,
            left: 12,
          },
          north: {
            width: 18,
            height: 10,
            top: 38.5,
            left: 29.5,
          },
          srcs: ['optimized/sg1530.mp4', 'sg/SG_w1130.jpg', 'sg/PSBB-Showroom-2014.jpg'],
        },
        {
          slug: 'safan-e-brake-premium',
          east: {
            width: 3,
            height: 3,
            top: 26,
            left: 65.8
          },
          south: {
            width: 2.5,
            height: 3.5,
            top: 17,
            left: 45,
          },
          west: {
            width: 2.5,
            height: 3,
            top: 29.5,
            left: 31.5,
          },
          north: {
            width: 3,
            height: 4,
            top: 37.5,
            left: 50,
          },
          srcs: ['safan-e-brake-premium/SafanDarley-E-Brake–Premium.mp4', 'safan-e-brake-premium/E-Brake-35-130T-Premium.jpg', 'safan-e-brake-premium/E-Brake-35-40T-Premium-Ergonomic-Staan.jpg'],
        },
        {
          slug: 'safan-e-brake-ultra',
          east: {
            width: 3.5,
            height: 3.5,
            top: 24,
            left: 62.5
          },
          south: {
            width: 3.5,
            height: 3.5,
            top: 19,
            left: 41.5
          },
          west: {
            width: 3.5,
            height: 3,
            top: 31.5,
            left: 34
          },
          north: {
            width: 4,
            height: 4,
            top: 35.5,
            left: 53
          },
          srcs: ['safan-e-brake-ultra/SafanDarley_E-brake_Ultra.mp4', 'safan-e-brake-ultra/E-Brake-Ultra.jpg', 'safan-e-brake-ultra/safandarley-4511-ultra.jpg'],
        },
        {
          slug: 'laser',
          east: {
            width: 3.5,
            height: 3.5,
            top: 45,
            left: 65.5,
          },
          south: {
            width: 11.5,
            height: 7.5,
            top: 11,
            left: 71.5,
          },
          west: {
            width: 11.5,
            height: 9,
            top: 8,
            left: 22,
          },
          north: {
            width: 4,
            height: 4,
            top: 35.5,
            left: 17.5,
          },
          srcs: ['laser/MS3N2-Clip-_1_con-carico-scarico.mp4', 'laser/laserprima.jpg', 'laser/Power_combo_tower02.jpg'],
        }
      ],
      hotspotPortraits: hotspotPortraits,
      hotspotPortraitTitles: {
        red: 'Din Säljavdelning',
        blue: 'Din Supportavdelning',
        green: 'Din Serviceavdelning',
        yellow: 'Din Reservdelsavdelning',
        white: 'Din Backoffice'
      },
      hotspots: [
        {
          slug: 'officeBlueThree',
          label: 'Support',
          color: 'blue',
          width: 4,
          height: 4,
          east: {
            top: 6.72,
            left: 46
          },
          south: {
            top: 34,
            left: 15.55
          },
          west: {
            top: 48.95,
            left: 55.29
          },
          north: {
            top: 27.53,
            left: 85.03
          },
          images: [],
          videos: []
        },
        {
          slug: 'officeBlueTwo',
          label: 'Support',
          color: 'blue',
          width: 4,
          height: 4,
          east: {
            top: 3.75,
            left: 41.25
          },
          south: {
            top: 36.82,
            left: 10.66
          },
          west: {
            top: 51.86,
            left: 61.38
          },
          north: {
            top: 25.42,
            left: 90.63
          },
          images: [],
          videos: []
        },
        {
          slug: 'officeBlueOne',
          label: 'Support',
          color: 'blue',
          width: 4,
          height: 4,
          east: {
            top: 6,
            left: 36.5
          },
          south: {
            top: 39.73,
            left: 13.87
          },
          west: {
            top: 50.23,
            left: 65.27
          },
          north: {
            top: 22.5,
            left: 87.5
          },
          images: [],
          videos: []
        },
        {
          slug: 'officeGreenThree',
          label: 'After Sales',
          color: 'green',
          width: 3,
          height: 3,
          east: {
            top: 8.05,
            left: 32.99
          },
          south: {
            top: 41.6,
            left: 17.48
          },
          west: {
            top: 47.47,
            left: 68.54
          },
          north: {
            top: 20,
            left: 84.54
          },
          images: [],
          videos: []
        },
        {
          slug: 'officeGreenTwo',
          label: 'After Sales',
          color: 'green',
          width: 3,
          height: 3,
          east: {
            top: 12.5,
            left: 25.03
          },
          south: {
            top: 46,
            left: 25.97
          },
          west: {
            top: 43.27,
            left: 76.22
          },
          north: {
            top: 15.19,
            left: 76.74
          },
          images: [],
          videos: []
        },
        {
          slug: 'officeGreenOne',
          label: 'After Sales',
          color: 'green',
          width: 9.5,
          height: 9.5,
          east: {
            top: 17.13,
            left: 32
          },
          south: {
            top: 40.18,
            left: 31.79
          },
          west: {
            top: 39.36,
            left: 68.85
          },
          north: {
            top: 19.48,
            left: 71.25
          },
          images: [],
          videos: []
        },
        {
          slug: 'officeRedFour',
          label: 'Sales',
          color: 'red',
          width: 3,
          height: 3,
          east: {
            top: 11,
            left: 27.69
          },
          south: {
            top: 44.53,
            left: 23.07
          },
          west: {
            top: 44.96,
            left: 73.68
          },
          north: {
            top: 17.08,
            left: 79.35
          },
          images: [],
          videos: [],
          portraits: []
        },
        {
          slug: 'officeRedThree',
          label: 'Sales',
          color: 'red',
          width: 3,
          height: 3,
          east: {
            top: 14.13,
            left: 22.59
          },
          south: {
            top: 47.42,
            left: 28.18
          },
          west: {
            top: 41.75,
            left: 78.5
          },
          north: {
            top: 13.15,
            left: 75.09
          },
          images: [],
          videos: [],
          portraits: []
        },
        {
          slug: 'officeRedTwo',
          label: 'Sales',
          color: 'red',
          width: 10,
          height: 10,
          east: {
            top: 23.13,
            left: 22.51
          },
          south: {
            top: 45.88,
            left: 41.79
          },
          west: {
            top: 33.36,
            left: 77.85
          },
          north: {
            top: 13.48,
            left: 62.76
          },
          images: [],
          videos: [],
          portraits: []
        },
        {
          slug: 'officeRedOne',
          label: 'Sales',
          color: 'red',
          directOpen: true,
          width: 5,
          height: 5,
          east: {
            top: 18.79, left: 14.29
          },
          south: {
            top: 51.51,
            left: 36.76
          },
          west: {
            top: 36.64,
            left: 86.46
          },
          north: {
            top: 8.06,
            left: 67.45
          },
          images: [],
          videos: [],
          portraits: []
        },
        {
          slug: 'officeYellowOne',
          label: 'Reservdelar',
          color: 'yellow',
          directOpen: true,
          width: 13.5,
          height: 7.5,
          east: {
            top: 22.79,
            left: 45.29,
          },
          south: {
            top: 32.51,
            left: 41.26,
          },
          west: {
            top: 33.51,
            left: 55.26,
          },
          north: {
            top: 26.06,
            left: 59.95,
          },
          images: [],
          videos: [],
          portraits: []
        },
        {
          slug: 'officeWhiteOne',
          label: 'Back Office',
          color: 'white',
          directOpen: true,
          width: 5,
          height: 5,
          east: {
            top: 15.79,
            left: 19
          },
          south: {
            top: 48.51,
            left: 32.5
          },
          west: {
            top: 40,
            left: 82
          },
          north: {
            top: 11.06,
            left: 71.6
          },
          images: [],
          videos: [],
          portraits: []
        },
        {
          slug: 'kitchen',
          label: 'Kök',
          width: 6,
          height: 6,
          east: {
            top: 9.56,
            left: 40.35
          },
          south: {
            top: 36.63,
            left: 18.46
          },
          west: {
            top: 46.5,
            left: 61.17
          },
          north: {
            top: 23.93,
            left: 82.16
          },
          images: [
            'interior/D1-kontorsbild.jpg',
            'interior/D2-kontorsbild.jpg'
          ],
          videos: [

          ]
        }
      ],
    }
  },
  methods: {
    printMousePos(event) {
      let body = document.body;
      let html = document.documentElement;
      
      let documentwidth = Math.max( body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth );
      
      let vw_top = (event.clientY / documentwidth) * 100;
      let vw_left = (event.clientX / documentwidth) * 100;
      vw_top + vw_left;
      // console.log( 'top: ' + vw_top.toFixed(2) + ', left: ' + vw_left.toFixed(2) );
    },
    updateMousePosition(event) {
      this.mouseleft = event.clientX + 'px';
      this.mousetop = event.clientY + 'px';
    },
    loadHotspotPortraits(){
      
    },
    setViewport(viewport){
      let self = this;
      self.hideModel = true;
      setTimeout(function(){
        self.viewport = viewport;
        self.hideModel = false;
      }, 250);
    },
    openVideoPopper(){
    },
    openImage(src){
      this.lightbox.image = require('../assets/'+ src );
    },
    setPortraits(portraits){
      this.portraits = portraits;
    },
    setPortraitsTitle(portraitstitle){
      this.portraitstitle = portraitstitle;
    },
    playVideo(src){
      this.videoHeight = '100vh';
      this.videoOpacity = 1;
      this.videoOffset = '0vh';

      let self = this;

      setTimeout(function(){
        self.videoDisplaying = true;
        self.videoSrc = require('../assets/videos/'+ src);
      }, 200);
    },
    closeVideo(){
      this.videoHeight = '0px';
      this.videoOpacity = 0;
      this.videoOffset = '50vh';

      let self = this;
      
      setTimeout(function(){
        self.videoDisplaying = false;
        self.videoSrc = '';
      }, 500);
    }
  },
  computed: {
    sprites: function() {
      return {
        roof: require('../assets/'+ this.viewport +'/roof.png'),
        // walls: require('../assets/'+ this.viewport +'/walls.png'),
        floor: require('../assets/'+ this.viewport +'/floor.png') 
      }
    },
    videoStyle: function(){
      return {
        top: this.videoOffset,
        maxHeight: this.videoHeight,
        opacity: this.videoOpacity
      }
    }
    // sprites: function(){
    //   console.log('Sprites loaded');
    //   this.sprites.roof = 'assets/east/roof.png',
    //   this.sprites.walls = 'assets/east/walls.png',
    //   this.sprites.floor = 'assets/east/floor.png' 
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.dev-only {
  display: none;
}

.presentation {
  overflow: hidden;
}

#cursor {
  width: 1.5vw;
  height: 1.5vw;
  border: 2px solid yellow;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2000;
  transform: translateY(-0.75vw) translateX(-0.75vw);
  pointer-events: none;
}

.viewport {
  position: relative;
  width: 100vw;
  height: 56.25vw;
  background: #9fa8a7;
  transition: all 0.25s;

  &.hidden {
    transform: rotateY(90deg);
  }

} 

.highlight {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 25vh;
  left: 35vh;
  z-index: 999;
  box-shadow: 0px 0px 48px rgba(255,255,255,0.5);
  border-radius: 50%;
  backdrop-filter: blur(2);
  // filter: blur(4px);
  // background: rgba(255,255,255,0.45);
}

.lightbox {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1002;
  background: rgba(0,0,0,0.65);

  .lightbox-inner {
    width: 90%;
    height: 90%;
    margin: 5%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.video-player {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 0px;
  outline: 40px solid #000;
  z-index: 10002;
  overflow: hidden;
  transition: all 0.15s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #000;

  .video-container {
    width: 100%;
    padding-bottom: 56.25%;
    background: #000;

    button.close {
      background: #cf0a2c;
      border: 2px solid #282A2B;
      display: block;
      width: 4vw;
      height: 4vw;
      padding: 0.5em;
      border-radius: 50%;
      top: 5px;
      right: 5px;
      z-index: 10005;
      position: absolute;
      background: #cf0a2c;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      
      span {
        font-size: 25px;
        font-weight: 700;
        margin-top: -3px;
        margin-right: -3px;
        transform: rotateZ(45deg);
      }

      &:focus {
        outline: none;
      }
    }
  }

  video {
    width: 100%;
    height: auto;
    position: absolute;
    top: 10px;
    left: 0px;
  }
}

.controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10001;

  .group {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
  }

  button {
    background: #cf0a2c;
    border: 2px solid #cf0a2c;
    color: #fff;
    border-radius: 0px;
    font-size: 16px;
    margin-left: 5px;
    min-width: 70px;
    text-transform: uppercase;
    padding: 0.3em 0.2em;

    &:focus {
      outline: none;
    }

    &.current {
      background: #282A2B;
    }
    
  }
}

.base-component {
  position: absolute;
  width: 100%;
  height: 100%;
}

.roof {
  z-index: 800;
  // background: red;
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 1s, opacity 0.25s;

  &.off {
    transform: translateY(-4000px) rotateZ(45deg) translateX(50vw);
  }
}

.walls {
  z-index: 400;
  background-size: contain;
  background-repeat: no-repeat;

  &.off {
    opacity: 0;
  }

  > * {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
  }
  
  .far {
    z-index: 400;
    // background: green;
  }
  .left {
    z-index: 500;
    // background: blue;
  }
  .right {
    z-index: 600;
    // background: purple;
  }
  .near {
    z-index: 700;
    // background: pink;
  }
}


.floor {
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.zoomfade {
  transform: scale(0.8);
}
.zoomfade-enter-active, .zoomfade-leave-active {
  transition: opacity .5s, transform .5s;
}
.zoomfade-enter, .zoomfade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0.8);
}

</style>
